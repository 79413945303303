/* Devices Breakpoints normals*/
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brands-collection {
  color: #fff;
  overflow: auto;
  width: 100%;
}
.brands-collection .hidden {
  display: none;
}
.brands-collection__wrapper {
  margin: 0 auto;
  max-width: 1312px;
}
.brands-collection__hr {
  border: 0;
  border-top: 1px solid #fff;
  clear: both;
  margin: 0;
  opacity: 0.15;
}
.brands-collection__hr:nth-child(1) {
  opacity: 0.6;
}
.brands-collection__hr:last-of-type {
  display: none;
}
.brands-collection__hr--main {
  margin-top: 86px;
  opacity: 0.6;
}
@media (min-width: 768px) {
  .brands-collection__hr--main {
    margin-top: 106px;
  }
}
.brands-collection__svg {
  background-color: #050033;
  display: block;
  fill: #fff;
  height: 70px;
  margin: -30px auto 45px;
  padding: 0 30px;
  position: relative;
  max-width: 250px;
}
.brands-collection__dl {
  overflow: auto;
  margin: 0;
}
.brands-collection__dt {
  font-style: italic;
  padding: 16px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
}
@media (min-width: 768px) {
  .brands-collection__dt {
    display: block;
    float: left;
    padding: 14px 0 0;
  }
}
.brands-collection__dd {
  margin: 0;
  padding: 6px 5px;
}
@media (min-width: 768px) {
  .brands-collection__dd {
    float: right;
    width: calc((100% + 24px) / 8 * 6 - 24px);
  }
}
@media (min-width: 1024px) {
  .brands-collection__dd {
    width: calc((100% + 24px) / 12 * 10 - 24px);
  }
}
.brands-collection__title {
  font-family: 'Unna', Georgia, serif;
  font-size: 24px;
}
.brands-collection__number {
  color: #8d88b4;
  font-size: 14px;
  font-style: normal;
}
@media (min-width: 768px) {
  .brands-collection__number {
    display: none;
  }
}
.brands-collection__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.brands-collection__elements {
  -ms-flex-preferred-size: calc((100% / 3) - 1px);
      flex-basis: calc((100% / 3) - 1px);
  list-style: none;
  min-width: 5.7rem;
}
.brands-collection__elements:nth-child(n + 4) {
  display: none;
}
@media (min-width: 768px) {
  .brands-collection__elements {
    -ms-flex-preferred-size: calc((100% / 6) - 1px);
        flex-basis: calc((100% / 6) - 1px);
    min-width: 5.3rem;
  }
  .brands-collection__elements:nth-child(n + 4) {
    display: block;
  }
}
@media (min-width: 1024px) {
  .brands-collection__elements {
    -ms-flex-preferred-size: calc(100% / 10);
        flex-basis: calc(100% / 10);
    min-width: 4.5rem;
  }
}
.brands-collection__elements--urls {
  display: block;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  transition: background 0.3s ease-in-out;
}
.brands-collection__elements--urls:hover,
.brands-collection__elements--urls:focus {
  background: rgba(255, 255, 255, 0.9);
}
.brands-collection__elements--urls:focus {
  background: #fff;
}
.brands-collection__elements--urls:focus svg {
  fill: #050033;
}
.brands-collection__elements--urls:active {
  background: #050033;
}
.brands-collection__elements--logos {
  display: block;
  fill: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: fill 0.2s;
  width: 100%;
}
.brands-collection__elements--logos:hover {
  fill: #050033;
}
.brands-collection__elements--logos:focus {
  fill: #050033;
}
.brands-collection__elements--logos:active {
  background: #fff;
  fill: #050033;
}
.brands-collection__allBrands {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  display: table;
  margin: 40px auto 20px;
  padding: 12px 32px;
  text-decoration: none;
  transition: background 0.2s, border 0.2s;
}
@media (min-width: 768px) {
  .brands-collection__allBrands {
    display: none;
  }
}
.brands-collection__allBrands:hover {
  background-color: #fff;
  color: #050033;
  text-decoration: none;
}
