@color-brand-900: #050033;
@color-text-inverse: #fff;
@color-background-light: #fff;

@color-button-secondary-inverse-hover: rgba(255, 255, 255, 0.9);
@color-brand-400: #8d88b4;

/* Devices Breakpoints normals*/
@small: 768px;
@medium: 1024px;
@large: 1279px;
@xlarge: 1440px;

@max-width: 1312px;
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brands-collection {
  .hidden {
    display: none;
  }

  color: @color-text-inverse;
  overflow: auto;
  width: 100%;

  &__wrapper {
    margin: 0 auto;
    max-width: @max-width;
  }

  &__hr {
    border: 0;
    border-top: 1px solid @color-background-light;
    clear: both;
    margin: 0;
    opacity: 0.15;

    &:nth-child(1) {
      opacity: 0.6;
    }

    &:last-of-type {
      display: none;
    }

    &--main {
      margin-top: 86px;
      opacity: 0.6;

      @media (min-width: @small) {
        margin-top: 106px;
      }
    }
  }

  &__svg {
    background-color: @color-brand-900;
    display: block;
    fill: @color-background-light;
    height: 70px;
    margin: -30px auto 45px;
    padding: 0 30px;
    position: relative;
    max-width: 250px;
  }

  &__dl {
    overflow: auto;
    margin: 0;
  }

  &__dt {
    font-style: italic;
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (min-width: @small) {
      display: block;
      float: left;
      padding: 14px 0 0;

    }
  }

  &__dd {
    margin: 0;
    padding: 6px 5px;

    @media (min-width: @small) {
      float: right;
      width: calc((100% + 24px) / 8 * 6 - 24px);
    }

    @media (min-width: @medium) {
      width: calc((100% + 24px) / 12 * 10 - 24px);
    }
  }

  &__title {
    font-family: 'Unna', Georgia, serif;
    font-size: 24px;
  }

  &__number {
    color: @color-brand-400;
    font-size: 14px;
    font-style: normal;

    @media (min-width: @small) {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  &__elements {
    flex-basis: calc((100% / 3) - 1px);
    list-style: none;
    min-width: 5.7rem;

    &:nth-child(n + 4) {
      display: none;
    }

    @media (min-width: @small) {
      flex-basis: calc((100% / 6) - 1px);
      min-width: 5.3rem;

      &:nth-child(n + 4) {
        display: block;
      }
    }

    @media (min-width: @medium) {
      flex-basis: calc(100% / 10);
      min-width: 4.5rem;
    }

    &--urls {
      display: block;
      height: 0;
      padding-bottom: 50%;
      position: relative;
      transition: background 0.3s ease-in-out;

      &:hover,
      &:focus {
        background: @color-button-secondary-inverse-hover;
      }

      &:focus {
        background: @color-background-light;

        svg {
          fill: @color-brand-900;
        }
      }

      &:active {
        background: @color-brand-900;
      }
    }

    &--logos {
      display: block;
      fill: @color-background-light;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: fill 0.2s;
      width: 100%;

      &:hover {
        fill: @color-brand-900;
      }

      &:focus {
        fill: @color-brand-900;
      }

      &:active {
        background: @color-background-light;
        fill: @color-brand-900;
      }
    }
  }

  &__allBrands {
    border: 1px solid @color-background-light;
    border-radius: 30px;
    color: @color-background-light;
    display: table;
    margin: 40px auto 20px;
    padding: 12px 32px;
    text-decoration: none;
    transition: background 0.2s, border 0.2s;

    @media (min-width: @small) {
      display: none;
    }

    &:hover {
      background-color: @color-background-light;
      color: @color-brand-900;
      text-decoration: none;
    }
  }
}
